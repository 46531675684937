import { nearestNeighbour } from "./pixelate";
import { quantize } from "./cq";
import { brickify } from "./brickify";
import { generateInstructions } from "./instructions";

/**
 * Generates the mosaic pattern from the image, size and palette chosen
 * 
 * @param {HTMLImageElement} image - the HTML Element of the users cropped image 
 * @param {int} size - size of baseplate the user selected 
 * @param {string} palette - the name of the palette the user selected 
 */
export async function generatePattern(image, size, palette){
        
    //pixelate the image
    const pixelated = await nearestNeighbour(image, size);

    //quantize the image
    const cq = await quantize(pixelated, size, palette);

    //'brickify' the image - split the quantized image up into 16x16 squares ready for the instructions

    //returns {[16 dataurls], [palette stud colours come from]}
    const bricked = await brickify(cq, image.src);
        
    
    await generateInstructions(bricked.imgs, bricked.genPalette, palette); 
}