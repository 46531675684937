import "../styles.css"
export const Header = () => {
  // Header with title - NO LONGER USED
    return (<>
        <header id = "header">
        <h1 id = "title">LEGO Mosiac Generator</h1>
        </header>
    </>);
}

export const Footer = () => {
  // Footer with disclaimer
    return (<>
      <div id = "footer">
        <p>
        LEGO® is a trademark of the LEGO Group of companies which does not sponsor, authorize or endorse this site
        </p>
      </div>
    </>);
}