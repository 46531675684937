import React from "react";
import ReactDOM from 'react-dom'
import "./styles.css";
import {Controls} from "./components/controls";
import { Footer } from "./components/headerFooter";
import { Help } from "./components/help";


const App = () => {
  return (<>
  
    <div id="App">
    
      {/* Image cropper and controls */}
      <Controls />

      {/* User Guide */}
      <Help />

      {/* Not Affiliated with lego footer */}
      <Footer />
    </div>
 </>)
}


const rootElement = document.getElementById('root')
ReactDOM.render(<App />, rootElement)
