import { useState } from 'react';
import { FiHelpCircle } from 'react-icons/fi';
import '../styles.css';
import { Button } from "@material-ui/core";

//User guide "help" component 
export const Help = () => {
    const [showPopup, setShowPopup] = useState(true);

    const togglePopup = () => {
        setShowPopup(!showPopup);
    };

    return (
        <>
            {/** Add the question mark icon to the document */}
            <button id="popup-btn" onClick={togglePopup}>
                <FiHelpCircle />
            </button>

            {/** If the pop-up state is true then show the pop-up */}
            
            {showPopup && (
                <div className="popup-overlay">
                    <div className="popup">
                        <div className="popup-content">
                
                            <h1 className = "help-heading" id = "title">Welcome!</h1>

                            <h2 className = "help-heading" id = "subheading">Generating a LEGO Mosaic Pattern has never been easier</h2>

  

                            <div className="help" id = "step1">
                                <h3 className = "help-heading" id = "subsubheading"> User Guide</h3>

                                <h4 className = "help-heading" id = "subsubheading"> Follow these 3 simple steps and you'll be ready to build</h4>
                                <p>Step 1: Upload and Crop your image</p>
                                <div className="step1-img-div" id = "step1-img">
                                    <img className = "help-img" src = "https://devweb2023.cis.strath.ac.uk/~drb20179/img/help/step1.png"></img>
                                    <img className = "help-img" src = "https://devweb2023.cis.strath.ac.uk/~drb20179/img/help/step1a.png"></img>

                                </div>
                                <p>To Crop and zoom simply scroll & drag using your mouse, pinch & drag using a touchscreen or use the slider to zoom in</p>
                            </div>

                            <div className="help" id = "step2">
                                <p>Step 2: Select your colour palette</p>
                                <img className = "help-img" src = "https://devweb2023.cis.strath.ac.uk/~drb20179/img/help/step%202.png"></img>
                                <p>Choose from a range of colour palettes from other LEGO art sets or use all colours available</p>
                            </div>

                            <div className="help" id = "step3">
                                <p>Step 3: Select your baseplate size</p>
                                <img className = "help-img" src = "https://devweb2023.cis.strath.ac.uk/~drb20179/img/help/step3.png"></img>
                                <p>Finally hit the GENERATE button and within a few seconds your image will become a piece of LEGO art!</p>
                                <p>Your pattern will automatically downloads as a PDF</p>
                            </div>
                            
                            <Button style = {{backgroundColor:"#A9DBB8", color:"#242624", width: "50%"}} variant="contained" className="close-btn" onClick={togglePopup}>
                            Enter Site
                            </Button>
                        </div>

                    </div>
                </div>
            )}
        </>
    );
};
