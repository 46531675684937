import kmeansQuantizer from "../library/kmeans/kmeans";
import colourData from "../json/palettes.json";

/**
 * 
 * @param {Uint8ClampedArray} image - The image to be quantized as imageData
 * @param {int} size - The size of the image
 * @param {String} palette - The identifier of the user chosen palette
 * @returns {Promise<Object>} - A promise that resolves with an object containing the quantized and colour swapped image and the palette of colours
 */
export function quantize (image, size, palette){
        
    return new Promise((resolve) => {
        // Get the colour palette from palettes.json 
        let colourPalette = [];
        for (let i = 0; i < colourData.length; i++){
            if (colourData[i].setNumber == palette){
                colourPalette = colourData[i].colours;
            }

        }

        // Set k - amount of colours in the quantized picture - to the size of the colour palette
        const k = colourPalette.length

        // Quantize the image using kmeans
        const quantizedImage = kmeansQuantizer.compute(image, k, false);
    
        // Hex -> Rgb
        let colourNames = [];
        if(colourPalette){
        let tmp = [];
        for (let i = 0; i < colourPalette.length; i++){
            tmp[i] = Hex2RGB(colourPalette[i].rgb);
            colourNames[i] = colourPalette[i].name;
            }
        colourPalette = tmp;
        }
        
        // Turn the quantized image data into an array of colours
        let qiColArr = arrToCols(quantizedImage.imageData)
        
            
        /**I honestly cannot remember why i added this however i'm not gonna completely remove it until i know for sure that it's completely useless i think there was a really specific case where a colour weirdly had a decimal value after quantizing but i've not been able to replicate it
         * // Get the palette used in the quantized image
         * let p = getPalette(qiColArr)
         * 
         * let qiPaletteArr = colsToArr(p,p.length);
         * 
         * // Round the colour values in the palettes as they're rounded in the final image, makes replacing colours later easier
         * for (let i = 0; i < qiPaletteArr.length; i++) {
         *   let rounded = Math.round(qiPaletteArr[i]);
         *   qiPaletteArr[i] = rounded        
         * }
         * 
         * qiPaletteArr = (arrToCols(qiPaletteArr, qiPaletteArr.length));
         */ 
    

        // Decide which colours in the LEGO palette should swap with which in the quantized palette
        let decisions = swapDecider(compare(qiColArr, colourPalette))

        // Do the swapping
        qiColArr = swapCols(qiColArr, decisions);
    

        // Change the array of colour objects back into an array of numbers
        const cqAndSwappedImage = colsToArr(qiColArr);
        
        // Replace the image data in the k-means output with the data from the swapped image
        for (let i = 0; i < quantizedImage.imageData.length; i ++){
            quantizedImage.imageData[i] = cqAndSwappedImage[i]
        }

        // Make the output useful again by turning it back into an image using a canvas
        const data = new ImageData(quantizedImage.imageData, size, size);
            
        const canvas = document.createElement('canvas');
        canvas.width = size;
        canvas.height = size;
        
        const ctx = canvas.getContext('2d');
        ctx.putImageData(data,0,0);


        resolve({"img": data, "palette": {"colours": colourPalette, "names": colourNames}});
    });

}

  /**
 * Compares the distance between colours in 2 lists
 * 
 * @param {Array<Object>} list1 - A list of colours to be compared to
 * @param {Array<Object>} list2 - A list of colours to be compared against
 * @returns {Array<Array<Object, Array<Object>>>} - Each colour along with an array of distances between other colours 
 */
  function compare(list1, list2) {

    // Adapted from ../library/kmeans/kmeans.js distance(...){} to accept 2 lists of colours and compares them outputting a list
    let comparison = [];

    for (let i = 0; i < list1.length; i++) {
        // For every color in list1
        let color1 = list1[i];
        let tmp = [];
        for (let j = 0; j < list2.length; j++) {
            let color2 = list2[j];
            // Calculate the distance from every colour in list 2

            // Distance between 2 colours
            let mr = 0.5 * (color1.red + color2.red),
            dr = color1.red - color2.red,
            dg = color1.green - color2.green,
            db = color1.blue - color2.blue;
            let distance = (2*dr*dr) + (4*dg*dg) + (3*db*db) + (mr * ((dr*dr)-(db*db))/256);
            tmp[j] = {"colour": color2, "distance": Math.sqrt(distance) / (3*255)  };     
                 
        }
        
        comparison[i] = [color1, tmp]
    }

    return comparison;
  }

  /**
   * Uses the distance between colours to decide which to swap them to
   * 
   * @param {Array<Array<Object, Array<Object>>>}compared - Each colour along with an array of distances between other colours 
   * @returns {Array<Object>} - An array of objects containing which colours swap to which
   */

  function swapDecider (compared){

    let swapped = [];

    // Pick out best colour to swap with 
    for(let i = 0; i < compared.length; i++){
        let potentialSwaps = compared[i][1];
        // Assume 1st is best to start
        let best = potentialSwaps[0];
        // Loop over the potential swaps to find the best swap
        for(let j = 0; j < potentialSwaps.length; j ++){
            if (potentialSwaps[j].distance < best.distance){
                best = potentialSwaps[j];
            }
        }
        // Add it to the array
        swapped[i] = {"from": compared[i][0], "to": best.colour};
    }

    return swapped;
  }

  /**
   * Swaps the colours in the image data to the colours the decided picked
   * 
   * @param {Array<Object>} imgData - Quantized image data as an array of colour objects
   * @param {Array<Object>} colourSwaps - An array of objects containing which colours swap to which} colourSwaps 
   * @returns {Array<Object>} - colour swapped image data as an array of colours
   */
  function swapCols(imgData, colourSwaps){
    let newImgData = [];
    
    // For every pixel in the image
    for (let i = 0; i < imgData.length; i++) {
        // loop through the swaps until it finds the current colour then swap it
        for(let j = 0; j < colourSwaps.length; j++){
            if(
                imgData[i].red === colourSwaps[j].from.red &&
                imgData[i].green === colourSwaps[j].from.green &&
                imgData[i].blue === colourSwaps[j].from.blue

                ){
                newImgData[i] = colourSwaps[j].to;
            }
        }
    }
    return newImgData;
  }

  /**
   * Change an array of colours from this [r,g,b,a,r,g,b,a,r,g,b,a...] to an array of colours like this [{r,b,g,a}, {r,g,b,a}...]
   * 
   * @param {Uint8ClampedArray} arr - Image Data array to be changed
   * @returns {Array<Object>} - Image Data as an array of colours
   */
  export function arrToCols (arr, size){
    let tmp = [];
    for (let i = 0 ; i < arr.length; i +=4){
            let colour = {
            "red": arr[i],
            "green": arr[i+1], 
            "blue": arr[i+2],
            "alpha": arr[i+3]};
        
        tmp[i/4]=colour;
        }
    return tmp;
  }

  /**
   * Change an array of colours from this [{r,b,g,a}, {r,g,b,a}...] to an array of colours like this [r,g,b,a,r,g,b,a,r,g,b,a...]
   * 
   * @param {Array<Object>} arr - Image Data array of colour objects to be changed
   * @returns {Uint8ClampedArray} - Image Data
   */
function colsToArr(arr, size) {
    let tmp = [];
    for (let i = 0; i < arr.length; i++) {
        if(!arr[i]){console.log(i);}
        let j = i * 4;
        tmp[j] = arr[i].red;
        tmp[j + 1] = arr[i].green;
        tmp[j + 2] = arr[i].blue;
        tmp[j + 3] = 255;
    }
    return tmp;
}

/**
 * Get quantized palette directly from image
 * 
 * @param {Array<Object>} imgData - The image data as an array of colour objects
 *  
 * @returns {Array<Object>} - The colour palette used in the image
 */
export function getPalette(imgData) {

    let palette = [];
    for (let i = 0; i < imgData.length; i++) {
        let color = imgData[i];
        // Check if the color already exists in the palette
        let exists = palette.some(existingColor => {
            return (
                existingColor.red === color.red &&
                existingColor.green === color.green &&
                existingColor.blue === color.blue &&
                existingColor.alpha === color.alpha
            );
        });
        // If it doesn't exist add it to the palette
        if (!exists) {
            palette.push(color);
        }
    }
   return palette;
}

/**
 * Change hex colour to RGB colour 
 * 
 * @param {String} hex - The hex value to be changed to rgb
 * @returns {Object} - The hex colour as an R,G,B Colour object
 */
export function Hex2RGB (hex){

    // Take the int equivenant of the R,G & B portions of a hex colour 
    let red = parseInt((hex[0] + hex[1]), 16);
    let green = parseInt((hex[2] + hex[3]), 16);
    let blue = parseInt((hex[4] + hex[5]), 16);
    const rgb = {"red": red, "green": green, "blue": blue};

    return rgb
}