import jsPDF from "jspdf";
import colourData from "../json/palettes.json";
import rgbHex from "rgb-hex";


/**
 * 
 * Generates the instructions of the generated mosaic and saves it to the users device.
 * 
 * @param {string[]} images - An array of dataURLs each corresponding to a section of the mosaic
 * @param {object[]} paletteInfo - An array of colour objects representing the palette of the final pattern
 * @param {String} setNumber - The set number of the palette used
 */

export async function generateInstructions(images, paletteInfo, setNumber) {
    
    // Initilise the output PDF
    const doc = new jsPDF({
    unit: "px",
    format: [1000, 1000]
    });
    doc.setFontSize(20);

    // Add the front page and the help grid
    await generateFrontPage(doc, images[0], images[1], paletteInfo, setNumber);

    // Add the help grid to the PDF taking 2 off the length of the images array to account for the 2 front page images
    addGrid(doc, images.length-2);

    doc.setFontSize(50)

    // Add the chunks of the mosaic as well as their corresponding number to the PDF
    for (let i = 2; i < images.length; i++) {
        const image = await loadImage(images[i]);
        doc.addPage();
        doc.addImage(image, "png", 150, 150, 700, 700);
        doc.text(`${i-1}`, 50, 100);
    }

    //Save the pattern instructions to users device
    doc.save("Mosaic-Pattern.pdf");
}

/**
 * Asynchronously loads a given image from a url
 * 
 * @param {dataUrl} imageUrl - URL of image to be loaded
 * @returns {Promise<HTMLImageElement>} - A promise that resolves with a loaded image
 */
function loadImage(imageUrl) {
    return new Promise((resolve) => {
        const image = new Image();
        image.onload = () => resolve(image);
        image.src = imageUrl;
    });
}

/**
 * Adds the front page with the source and pixelated images as well as the palette information to the instructions PDF 
 *  
 * @param {object} doc - The PDF document the page is getting added to
 * @param {dataUrl} src - The source image before any processing
 * @param {dataUrl} pxl8 - The pixelated and quantized image
 * @param {object[]} paletteInfo - An array of colour objects representing the palette of the final pattern
 * @param {String} setNumber - The set number of the palette used
 */

async function generateFrontPage(doc, src, pxl8, paletteInfo, setNumber) {

    // Load the 2 images for the front page and add them to the PDF
    const srcImg = await loadImage(src);
    const pxl8Img = await loadImage(pxl8);

    doc.addImage(srcImg, "png", 100, 100, 375, 375);
    doc.addImage(pxl8Img, "png", 525, 100, 375, 375);

    // Add the palette to the page
    addPalette(doc, paletteInfo, setNumber);

    // Add the website name to the page
    doc.text(350, 950, "Generated using brickmosaicgenerator.com");
}

/**
 * Adds the colour palette to the PDF
 * 
 * @param {object} doc - The PDF document the page is getting added to
 * @param {object[]} paletteInfo - An array of colour objects representing the palette of the final pattern
 * @param {String} setNumber - The set number of the palette used
 */
function addPalette(doc, paletteInfo, setNumber) {
    
    // Get all the colours from the user selected palette
    let allCols = [];
    for (let i = 0 ; i < colourData.length; i ++){
        if (colourData[i].setNumber === setNumber){
            allCols = colourData[i].colours;
        }
    }

    // Set the maximum columns and rows the pallet table has 
    const columns = 5
    const rows = 11
    
    // Get the Names of the colours used in the generated pattern
    const names = [];
    for (let i = 0; i < paletteInfo.length; i++) {
        const { red, green, blue } = paletteInfo[i];
        const colour = rgbHex(red, green, blue);

        for (let j = 0; j < allCols.length; j++) {
            if (allCols[j].rgb === colour.toUpperCase()) {
                names[i] = allCols[j].name;
            }
        }

    }


    // Add the colours along with their name and number to the palette table
    for (let i = 0; i < columns; i++) {
        for (let j = 0; j < rows; j++) {

            // Calculate which colour we're on
            const k = i * rows + j;
            if (k < paletteInfo.length) {
                /**Add it + info to the palette table */ 

                // Calculate the starting x & y coordinates of the colour info 
                const x = 100 + i * 200;
                const y = 515 + j * 35;
                
                // This is the number of the colour (y-offset of 5 is to line it up with the center of the circle better)
                doc.text(x , y + 5, `${k}`);

                // Add the colour circle
                doc.setFillColor(paletteInfo[k].red, paletteInfo[k].green, paletteInfo[k].blue);
                doc.circle(x + 35, y, 15, 'FD');

                // This is the name of the colour (y-offset of 5 again is to line it up with the center of the circle better) 
                doc.text(names[k], x + 60, y + 5);
            }
        }
    }
}

/**
 * Adds a help grid to the PDF 
 * 
 * @param {object} doc - The PDF document the page is getting added to
 * @param {int} n - The number of sections the grid should have
 */
function addGrid(doc, n) {
    doc.addPage();

    const rows = Math.sqrt(n);
    const columns = rows;

    const size = 800 / rows;
    const width = 800;
    const height = 800;

    // Create a canvas to hold the grid
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = width;
    canvas.height = height;

    // Add the squares to the grid
    ctx.lineWidth = 10;
    ctx.font = "50px Arial"; 
    ctx.textAlign = "center";

    let count = 1;
    for (let y = 0; y < columns; y++) {
        for (let x = 0; x < rows; x++) {

            // Calculate where the square is going then add it and it's number to the canvas
            const xPos = x * size;
            const yPos = y * size;
            ctx.strokeRect(xPos, yPos, size, size);

            //Number goes in center of the square
            ctx.fillText(count, xPos + size / 2, yPos + size / 2);
            
            count++;
        }
    }

    // Add the grid to the PDF
    const dataUrl = canvas.toDataURL();
    doc.addImage(dataUrl, "png", 100, 100, 800,800);
}


