import { arrToCols, getPalette } from "./cq";


/**
 * Formats the pixelated and quantized image ready to be added to the instructions PDF
 * 
 * @param {Object} imgData - an object containing the pixelated and quantized image data and the full palette information
 * @param {dataURL}} src - The source image as a dataURL
 * @returns {Promise<Object>} - A promise resolving in an object containing the data urls of the all the images to be added to the PDF as well as the palette used in the image
 */
export function brickify(imgData, src) {
   return new Promise((resolve) => {
        const data = imgData.img;
        const chosenPalette = getP(imgData.img);

        let imgs = [];
        let studs = [];

        // Add the source and generated image to the array
        imgs[0] = src;

        // Generated image is in imagedata format so use a canvas to retrieve a dataURL
        const tCanvas = document.createElement("canvas");
        const tCtx = tCanvas.getContext("2d");
        tCanvas.width = data.width;
        tCanvas.height = data.height;
        tCtx.putImageData(data, 0,0)
        let img = new Image();
        img.onload = function(){
            tCtx.clearRect(0, 0, tCanvas.width, tCanvas.height);
            tCanvas.height = 800;
            tCanvas.width = 800;
            tCtx.imageSmoothingEnabled = false;
            tCtx.drawImage(img,0,0,800,800);
            imgs[1] = tCanvas.toDataURL()
        }
        img.src = tCanvas.toDataURL();
        
        
        // To make the instructions easier to follow split the generated image into blocks
        const blocks = splitImageData(data);

        // Add the stud overlay to each block to make it look like LEGO
        for (let i = 0; i < blocks.length; i++) {
            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = blocks[i].width;
            canvas.height = blocks[i].height;

            ctx.putImageData(blocks[i], 0, 0);
            studs[i] = getStudNumbers(blocks[i].data, chosenPalette);

            let overlay = new Image();
            overlay.onload = function () {
                let tmpimg = new Image();
                tmpimg.onload = function () {

                    // Blow-up the image
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    canvas.width = 800;
                    canvas.height = 800;
                    ctx.imageSmoothingEnabled = false;
                    ctx.drawImage(tmpimg, 0, 0, 800, 800);

                    // Draw the overlay image on top of the blown-up image
                    ctx.drawImage(overlay, 0, 0, 800, 800);
                    
                    // Add it to the images array
                    imgs[i + 2] = canvas.toDataURL();
        
                    // If all the blocks have been added to the array resolve the promise
                    if (i === blocks.length - 1) {
                        resolve({"imgs": imgs, "genPalette": chosenPalette});
                    }
                };

                tmpimg.src = canvas.toDataURL();
            };
            overlay.src = studOverlay(studs[i]);
        }
    });
}

/**
 * Get the palette from the generated image
 * 
 * @param {ImageData} image - Image data of the image the palette is to be retrived from
 * @returns {Array<Object>} - The palette used in the image
 */
function getP(image){
    let pixels = arrToCols(image.data, image.width*image.height*4)
    let palette = getPalette(pixels);
    return palette
}


/**
 * Splits the imageData up into blocks of a set size
 * 
 * @param {ImageData} imageData - the image data of the image to be split 
 * @returns {Array<ImageData>} - An array of imagedata each containing the data of a block from the original image
 */
function splitImageData(imageData) {

    // Set the block size and the number of blocks in each row and collumn
    const blockSize = 16; 
    const blocksPerRow = imageData.width / blockSize; 
    const blocksPerColumn = imageData.height / blockSize;
    
    const blocks = [];
    

    for (let y = 0; y < blocksPerColumn; y++) {
        for (let x = 0; x < blocksPerRow; x++) {
            // Calculate the starting coords
            const startX = x * blockSize;
            const startY = y * blockSize;
            
            // Extract the pixels
            const blockData = new Uint8ClampedArray(blockSize * blockSize * 4);
            let index = 0;
            for (let j = 0; j < blockSize; j++) {
                for (let i = 0; i < blockSize; i++) {
                    // Calculate the start of the pixel's index 
                    const pixelIndex = ((startY + j) * imageData.width + (startX + i)) * 4;

                    // Get the pixels RBGA data
                    blockData[index++] = imageData.data[pixelIndex];
                    blockData[index++] = imageData.data[pixelIndex + 1];
                    blockData[index++] = imageData.data[pixelIndex + 2];
                    blockData[index++] = imageData.data[pixelIndex + 3];
                }
            }
            
            // Make the block an image data object and add it to the array
            blocks.push(new ImageData(blockData, blockSize, blockSize));
        }
    }
    
    return blocks;
}

/**
 * Get the stud numbers of all the pixels in an image
 * 
 * @param {Uint8ClampedArray} imageData - the image data of the image we're getting the stud numbers from
 * @param {Array<Object>} palette - The palette used in the generated image 
 * @returns {Array<int>} - the colour-key numbers that correspond to each pixel in the image
 */
function getStudNumbers(imageData, palette){
 
    let studNumbers = []

    let formattedImageData = arrToCols(imageData, imageData.length);

    // For each colour in the formated image data extract which number of colour it matches to in the palette
    for (let i = 0; i < formattedImageData.length; i ++){
        for (let j = 0; j < palette.length; j ++){
            if(
                formattedImageData[i].red == palette[j].red &&
                formattedImageData[i].green == palette[j].green &&
                formattedImageData[i].green == palette[j].green
            ){studNumbers[i] = j}
        }
    }
    return studNumbers
}

/**
 * Generates an overlay image to make an image look like LEGO studs with its corresponding colour number in the center of each
 * 
 * @param {Array<int>} studs - the stud colour numbers of each pixel in an image 
 * @returns {dataURL} - the dataURL of the overlay image 
 */
function studOverlay(studs) {

    // Create a canvas and set its fill and font properties
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    canvas.width = 800;
    canvas.height = 800;
    ctx.fillStyle = "white";
    ctx.fillRect(0, 0, canvas.width, canvas.height);

  
    const fontSize = 30;
    ctx.font = `${fontSize}px Arial`;
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    ctx.fillStyle = "#242424";

    // Radius of the cut out circles
    const radius = 25;

    // Calculate the number of rows and columns- each block is 16 x 16 and each image is 800x800 hence the factor of 50
    const rows = canvas.height / 50;
    const columns = canvas.width / 50;

    // Add the circular cutouts with stud numbersin the center
    for (let row = 0; row < rows; row++) {
        for (let col = 0; col < columns; col++) {

            // Calculate where the center of the cutout will go
            const x = col * 50 + 25;
            const y = row * 50 + 25;

            // Add the cutout
            ctx.globalCompositeOperation = "destination-out"; 
            ctx.beginPath();
            ctx.arc(x, y, radius, 0, Math.PI * 2);
            ctx.fill();
            ctx.closePath();
            ctx.globalCompositeOperation = "source-over"; 

            // Add the Stud number to the center twice one white one dark grey so the number is visible on any colour

            const studIndex = row * columns + col; 
            const studNumber = studs[studIndex];
            ctx.strokeStyle = "white";
            ctx.lineWidth = 2;
            ctx.strokeText(studNumber, x, y);
            ctx.fillStyle = "#242424"; 
            ctx.fillText(studNumber, x, y);
        }
    }
    return canvas.toDataURL();
}