/**
 * Applies Nearest Neighbour Pixelation to an image
 * 
 * @param {HTMLImageElement} image - The image to be pixelated
 * @param {int} size - The size to which the image will be pixelated to
 * @returns {Promise<Uint8ClampedArray>} - A promise that resolves with a pixelated image as imageData
 */

export function nearestNeighbour(image, size){

    return new Promise((resolve) => {   
        // Calculate how much the image will be scaled down by
        const scaler = image.width/size;

        
        /**
         * Create 2 canvas' 
         *  1st to hold the original image
         * 2nd to geneerate the new image in
         */

        const srcImgCanvas = document.createElement("canvas");
        srcImgCanvas.width = image.width
        srcImgCanvas.height = image.height
        const srcCtx = srcImgCanvas.getContext("2d", {willReadFrequently:true});

        const newCanvas = document.createElement("canvas");
        newCanvas.width = size;
        newCanvas.height = size;
        const newCtx = newCanvas.getContext("2d", { willReadFrequently: true });
        

        // Draw the source image
        srcCtx.drawImage(image, 0, 0, image.width, image.height);

        // Pixelate!
        for (let y = 0; y < size; y++){
            for(let x = 0; x < size; x++){
                // The nearest neighbour to a new pixel will equal the original pixel it would've been if it weren't scaled (rounded because of decimals, specifically down to avoid going over the original image's size)
                const origX = Math.floor(x*scaler);
                const origY = Math.floor(y*scaler);
                

                // Now get the original pixel's colour and set the the corrisponding pixel in the new image to that
                const colour = srcCtx.getImageData(origX, origY, 1, 1).data
                newCtx.fillStyle = `rgb(${colour[0]}, ${colour[1]}, ${colour[2]})`;
                newCtx.fillRect(x,y, 1,1);
            }
        }
        
        resolve(newCtx.getImageData(0,0, newCanvas.width, newCanvas.height).data);
    });
}