import ImageUploading from "react-images-uploading";
import { Button } from "@material-ui/core";

// See cropper.js for attribution
export const ImageUploadingButton = ({ value, onChange, ...props }) => {
    return (
      <ImageUploading value={value} onChange={onChange}>
        {({ onImageUpload, onImageUpdate }) => (
          <Button
            id="upload-image"
            variant="contained"
            onClick={value ? onImageUpload : () => onImageUpdate(0)}
            {...props}
          >
            Choose Image
          </Button>
        )}
      </ImageUploading>
    );
  };
