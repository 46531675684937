import Compressor from 'compressorjs';
import dataURLtoBlob from 'dataurl-to-blob';

/**
 * Compresses big images to make the generation quicker and fit with canvas limits on IOS
 * 
 * @param {Array<Object>} image - The image to be compressed before cropping
 * @returns {dataURL} - The compressed image
 */
export async function compress(image){
  
  /**
   *  Adapeted from
   *  https://github.com/ValentinH/react-easy-crop/issues/91#issuecomment-766167263
   * 
   *  to compress a bit less and return as a dataurl rather than a blob
   */  


    const blob = dataURLtoBlob(image[0].dataURL);

    const compressedImage = await new Promise((resolve, reject) => {
      new Compressor(blob, {
        maxWidth: 2000,
        maxHeight: 2000,
        success(result) {
          resolve(result);
        },
        error(err) {
          reject(err);
        },
      });
    });

    image[0].dataURL = URL.createObjectURL(compressedImage);

    return image;
}
