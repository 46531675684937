import React, { useState } from "react";
import "../styles.css";
import { Button } from "@material-ui/core";
import { ImageCropper } from "../library/cropping/cropper";
import { generatePattern } from "../generation/generate";
import setDetails from "../json/palettes.json";
import baseplates from "../json/baseplates.json";
import { compress } from "../generation/compress";
import { ImageUploadingButton } from "./imageButton";


const setNames = getNames();
const bpSizes = getBaseplates();

export const Controls = () => {
  const [image, setImage] = useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);

  // Function that checks an image has been uploaded and sends it to be made into a mosaic pattern
  const generateMosaic = () => {
    if (document.getElementById("uploaded-image").innerHTML !== "") {
      generatePattern(
        document.getElementById("cropped-image"),
        document.getElementById("size").value,
        document.getElementById("palette").value
      );
    } else {

      alert("Please upload an image before generating!")
    }
  };
  

  return (
    <div className="controls-container">
        <label htmlFor="upload-image">

          {/** Div that holds the uploaded and cropped image */}
          <div id="uploaded-image">
          </div>
        </label>

        {/** See cropper.js for attribution*/}
        <ImageCropper
          open={dialogOpen}
          image={image.length > 0 && image[0].dataURL}
          onComplete={(imagePromise) => {
            imagePromise.then((image) => {
              setCroppedImage(image);
              setDialogOpen(false);
            });
          }}
          containerStyle={{
            position: "relative",
            width: "100%",
            height: 300,
            background: "#242424",
            
          }}
        />
        <div className="controls">
        <ImageUploadingButton
          value={image}
          onChange={async (newImage) => {
            // When uploaded the image will be sent to the compressor and will be compressed if it's too big
            try {
              const compressedImg = await compress(newImage);
              setDialogOpen(true);
              setImage(compressedImg);
            } catch (error) {
              alert("Error compressing image:", error);
            }
          }}
        />
            {/** Palette and Size selectors */}
            <label htmlFor="palette" className="cLabel">
              Colour Palette
            </label>
            <select id="palette" name="palette">
              {setNames.map(([setNumber, setName]) => (
                <option key={setNumber} value={setNumber}>
                  {setName} {setNumber && `(${setNumber})`}
                </option>
              ))}
            </select>

            <label htmlFor="size" className="cLabel">
              Baseplate Size
            </label>
            <select id="size" name="size">
              {bpSizes.map((size) => (
                <option key={size} value={size}>
                  {size} x {size}
                </option>
              ))}
            </select>

          {/** Generate Button */}
          <Button style = {{backgroundColor:"#A9DBB8", color:"#242624"}} variant="contained" onClick={generateMosaic}>
            Generate
          </Button>

          
        </div>
    </div>
  );
};

// Simple function to get the set details from the json list
function getNames() {
  let names = [];
  for (let i = 0; i < setDetails.length; i++) {
    names[i] = [setDetails[i].setNumber, setDetails[i].name];
  }
  return names;
}

// Simple function to get the baseplate sizes from the json list
function getBaseplates() {
  let sizes = [];
  for (let i = 0; i < baseplates.length; i++) {
    sizes[i] = baseplates[i].size;
  }
  return sizes;
}
