import React, { useState } from "react";
import "../../styles.css";
import Cropper from "react-easy-crop";
import { Button, Dialog, DialogContent, DialogActions, Slider } from "@material-ui/core";
import { cropImage } from "./cropUtils";

/**
 * Adapted from
 * https://codesandbox.io/p/sandbox/react-image-upload-and-crop-9tvzs?file=%2Fsrc%2FcropUtils.js%3A10%2C66
 * 
 * With modified aspect ratio, zoom slider and styling
 */
export const ImageCropper = ({
  open,
  image,
  onComplete,
  containerStyle,
  ...props
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const handleZoomChange = (event, newZoom) => {
    setZoom(newZoom);
  };

  return (
    <Dialog open={open} fullWidth>
      <DialogContent>
        <div style={containerStyle}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            aspect={1}
            onCropChange={setCrop}
            onCropComplete={(_, croppedAreaPixels) => {
              setCroppedAreaPixels(croppedAreaPixels);
            }}
            onZoomChange={setZoom}
            {...props}
          />
        </div>

      </DialogContent>

      <DialogActions>
        <div id = "crop-controls">
          <Slider
              value={zoom}
              min={1}
              max={3}
              step={0.1}
              onChange={handleZoomChange}
              aria-labelledby="zoom-slider"
            />
            <Button
              variant="contained" 
              onClick={() =>
                onComplete(cropImage(image, croppedAreaPixels, console.log))
              }
            >
            Crop
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};
